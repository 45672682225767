<script>
import { mapState } from "vuex";
import Languages from "@/components/widgets/languages";

export default {
  locales: {
    pt: {
      'Settings': 'Configurações',
      'My Account': 'Minha Conta',
      'My Address': 'Meus Endereços',
      'Bank Account': 'Conta Bancária',
      'My Password': 'Minha Senha',
      'Password Security' : 'Senha de Segurança',
      'Logout': 'Sair',
    },
    es: {
      'Settings': 'Configuraciones',
      'My Account': 'Mi Cuenta',
      'My Address': 'Mi Endereços',
      'Bank Account': 'Cuenta Bancaria',
      'My Password': 'Mi Contraseña',
      'Password Security' : 'Contraseña de Seguridad',
      'Logout': 'Cerrar Sesión',
    }
  },
  components: { Languages },
  props: {
    user: {
      type: Object,
      required: false,
      default: () => ({})
    },
    isCondensed: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      avatar: this.$store.state.account.user.avatar,
    };
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  created() {
  },
  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    }
  }
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <button
            id="vertical-menu-btn"
            type="button"
            class="btn btn-sm px-3 font-size-20 header-item"
            @click="toggleMenu"
          >
          <i class="fa fa-fw fa-bars"></i>
        </button>

      </div>

      <div class="d-flex">
        <router-link tag="a" to="/" class="logo">
          <img style="height:45px;" src="@/assets/images/logo-header.png" />
        </router-link>
      </div>

      <div class="d-flex">
        <Languages></Languages>

        <b-dropdown right variant="black" toggle-class="header-item p-2">
          <template v-slot:button-content>
            <img
              v-if="account.user.avatar"
              class="rounded-circle header-profile-user"
              :src="account.user.avatar"
              alt=""
            />
            <img
              v-else
              class="rounded-circle header-profile-user"
              :src="'https://ui-avatars.com/api/?background=017F43&color=fff&name=' + account.user.name"
              alt=""
            />
            <span class="d-none d-xl-inline-block ml-1">{{ account.user.username }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <router-link tag="a" to="/account" class="dropdown-item">
            <span>{{ t('My Account') }}</span>
          </router-link>
          <router-link tag="a" to="/account/documents" class="dropdown-item">
            <span>{{ t('Meus Documentos') }}</span>
          </router-link>
          <router-link tag="a" to="/contracts" class="dropdown-item">
            <span>{{ t('Meus Contratos') }}</span>
          </router-link>
          <router-link tag="a" to="/account/address" class="dropdown-item">
            <span>{{ t('My Address') }}</span>
          </router-link>
          <router-link tag="a" to="/account/pix" class="dropdown-item">
            <span>{{ t('Meu PIX') }}</span>
          </router-link>
          <router-link tag="a" to="/account/password" class="dropdown-item">
            <span>{{ t('My Password') }}</span>
          </router-link>
          <router-link tag="a" to="/account/password/security" class="dropdown-item">
            <span>{{ t('Password Security') }}</span>
          </router-link>
          <div class="dropdown-divider"></div>
          <router-link tag="a" to="/logout" class="dropdown-item mb-2">
            <span>{{ t('Logout') }}</span>
          </router-link>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>
